import *  as ActionTypes from "./contactUsActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false
};

export const contactUsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_CONTACT_US:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_CONTACT_US_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_CONTACT_US_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}