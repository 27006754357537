import * as actionType from "./orderActionType";

const initialState = {
  isLoading: false,
  isAdded: false
};

export const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_TO_ORDER:
            return {
                ...state,
                isLoading: true,
                isAdded: false
            };
        case actionType.ADD_TO_ORDER_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.ADD_TO_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAdded: true
            };
        case actionType.REMOVE_IS_ADDED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAdded: false
            };
        default:
            return state;
    }
}