import * as actionType from "./productActionType";

const initialState = {
  isLoading: false,
  product: {},
  stoneFamilies: [],
  locationIndex: null,
  stoneIndex: null,
  cProduct: {}
};

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_PRODUCT:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                product: action.payload ? action.payload.data : {},
            };
        case actionType.FETCH_STONE_FAMILY:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_STONE_FAMILY_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_STONE_FAMILY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                stoneFamilies: action.payload ? action.payload.data : [],
                locationIndex: action.payload ? action.payload?.i : null
            };
        case actionType.FETCH_STONES:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_STONES_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_STONES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                stones: action.payload ? action.payload.data : [],
                stoneIndex: action.payload ? action.payload?.i : null
            };
        case actionType.CONFIGURE_PRODUCT:
            return {
                ...state,
                isLoading: true
            };
        case actionType.CONFIGURE_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CONFIGURE_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                cProduct: action.payload.data || {}
            };
        default:
            return state;
    }
}