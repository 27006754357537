export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const FETCH_PRODUCT_FAILURE = "FETCH_PRODUCT_FAILURE";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";

export const FETCH_STONE_FAMILY = "FETCH_STONE_FAMILY";
export const FETCH_STONE_FAMILY_FAILURE = "FETCH_STONE_FAMILY_FAILURE";
export const FETCH_STONE_FAMILY_SUCCESS = "FETCH_STONE_FAMILY_SUCCESS";

export const FETCH_STONES = "FETCH_STONES";
export const FETCH_STONES_FAILURE = "FETCH_STONES_FAILURE";
export const FETCH_STONES_SUCCESS = "FETCH_STONES_SUCCESS";

export const CONFIGURE_PRODUCT = "CONFIGURE_PRODUCT";
export const CONFIGURE_PRODUCT_FAILURE = "CONFIGURE_PRODUCT_FAILURE";
export const CONFIGURE_PRODUCT_SUCCESS = "CONFIGURE_PRODUCT_SUCCESS";