import * as actionType from "./categoryActionType";

const initialState = {
  isLoading: false,
  isPLoading: false,
  filters: [],
  products: [],
  count: 0,
  category: {}
};

export const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_PRODUCTS:
            return {
                ...state,
                isPLoading: true,
            };
        case actionType.FETCH_PRODUCTS_FAILURE:
            return {
                ...state,
                isPLoading: false,
            };
        case actionType.FETCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                isPLoading: false,
                products: action.payload ? action.payload.data.products : [],
                count: action.payload ? action.payload.data.count : 0,
                category: action.payload ? action.payload.data.category : {},
                filters: action.payload ? action.payload.data.attributes : []
            };
        default:
            return state;
    }
}