export const FETCH_CART = "FETCH_CART";
export const FETCH_CART_FAILURE = "FETCH_CART_FAILURE";
export const FETCH_CART_SUCCESS = "FETCH_CART_SUCCESS";

export const UPDATE_CART = "UPDATE_CART";
export const UPDATE_CART_FAILURE = "UPDATE_CART_FAILURE";
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";

export const DELETE_CART = "DELETE_CART";
export const DELETE_CART_FAILURE = "DELETE_CART_FAILURE";
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS";

export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_CART_FAILURE = "ADD_TO_CART_FAILURE";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";

export const CHANGE_ADD_TO_FALSE = "CHANGE_ADD_TO_FALSE";