import { combineReducers } from 'redux';
import { userReducer as userPage } from './../api/user/userReducer';
import { categoryReducer as categoryPage } from "./../api/category/categoryReducer";
import { cartReducer as cartPage } from "./../api/cart/cartReducer";
import { orderReducer as orderPage } from "./../api/order/orderReducer";
import { productReducer as productPage } from "./../api/product/productReducer";
import { contactUsReducer as contactUsPage } from "./../api/contactUs/contactUsReducer";

const appReducer = combineReducers({
    userPage,
    categoryPage,
    productPage,
    contactUsPage,
    cartPage,
    orderPage
});

export default appReducer;