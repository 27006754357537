import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from './App/protected.route';
import Loader from './shared/Loader';
const DashboardContainer = lazy(() => import('./views/dashboard/dashboard.container'));
const DetailContainer = lazy(() => import('./views/detail/detail.container'));
const ListContainer = lazy(() => import('./views/list/list.container'));
const ContactContainer = lazy(() => import('./views/contact-us/contact-us.container'));
const PrivacyPolicy = lazy(() => import('./views/privacy-policy/privacy-policy.container'));
const ReturnPolicy = lazy(() => import('./views/return-policy/return-policy.container'));
const TermsCondition = lazy(() => import('./views/terms-condition/terms-condition.container'));
const ShippinContainer = lazy(() => import('./views/shipping-policy/shipping-policy.container'));
const Checkout = lazy(() => import('./views/checkout/checkout.container'));
const Cart = lazy(() => import('./views/cart/cart.container'));
const OrderConfirmation = lazy(() => import('./views/order-confirmation/order-confirmation.container'));

export const RoutePath = () => (
  <Suspense fallback={<Loader loading={true}/>}>
    <Routes>
      <Route path='/' element={<DashboardContainer />} />
      <Route path='/product/:series/:categoryId/:groupId/:productId' element={<DetailContainer />} />
      <Route path='/category/:id' element={<ListContainer />} />
      <Route path='/contact-us' element={<ContactContainer />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='/return-policy' element={<ReturnPolicy />} />
      <Route path='/terms-condition' element={<TermsCondition />} />
      <Route path='/shipping-policy' element={<ShippinContainer />} />
      <Route path='/checkout' element={<Checkout />} />
      <Route path='/cart' element={<Cart />} />
      <Route path='/order-confirmation' element={<OrderConfirmation />} />
    </Routes>
  </Suspense>
);
