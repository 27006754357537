import * as actionType from "./cartActionType";

const initialState = {
  isLoading: false,
  cart: [],
  isAdded: false
};

export const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_CART:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.FETCH_CART_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.FETCH_CART_SUCCESS:
            return {
                ...state,
                isLoading: false,
                cart: action.payload ? action.payload.data : [],
            };
        case actionType.ADD_TO_CART:
            return {
                ...state,
                isLoading: true,
                isAdded: false
            };
        case actionType.ADD_TO_CART_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.ADD_TO_CART_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAdded: true
            };
            case actionType.ADD_TO_CART:
            return {
                ...state,
                isLoading: true,
                isAdded: false
            };
        case actionType.ADD_TO_CART_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.ADD_TO_CART_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAdded: true
            }
        case actionType.UPDATE_CART:
            return {
                ...state,
                isLoading: true,
                isAdded: false
            };
        case actionType.UPDATE_CART_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.UPDATE_CART_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAdded: true
            };
        case actionType.DELETE_CART:
            return {
                ...state,
                isLoading: true,
                isAdded: false
            };
        case actionType.DELETE_CART_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.DELETE_CART_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAdded: true
            };
        case actionType.CHANGE_ADD_TO_FALSE:
            return {
                ...state,
                isAdded: false
            }
        default:
            return state;
    }
}